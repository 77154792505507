import React, { useEffect, useReducer } from "react";

import { DateRangePickerProps } from "@amzn/awsui-components-react/uxdg";
import { Button, DateRangePicker, Header, HelpPanel, Spinner } from "@amzn/awsui-components-react-v3/polaris";
import { useNavigate } from "react-router-dom";

import { ERROR_ANSWER, skillsMapping } from "../../../common/constants";
import { Conversation } from "../../../common/types/Conversation";
import { getConversationHistory, getMessagesByConversationId } from "../../../utils/CommonUtils";

interface SideNavProps {
  state: any;
  dispatch: any;
  conversationId: string;
  debugMessage: any;
  messageHistory: any;
  autoSpeak: boolean;
  skills: any;
  userAlias: string;
  isSkillLocked: boolean;
  messageCount: number;
  smartRoutingDetectedSkillLabel: string;
}

interface SideNavState {
  todayList: any[];
  pastSevenDaysList: any[];
  thisMonthList: any[];
  lastMonthList: any[];
  olderList: any[];
  datePickerList: any[];
  dateRange: DateRangePickerProps.AbsoluteValue | null;
  isDpLoading: boolean;
  dpLoadMore: boolean;
  dpPageIndex: number;
  newTodayConversations: any[];
  conversationIds: any;
}

const initialState: SideNavState = {
  todayList: [],
  pastSevenDaysList: [],
  thisMonthList: [],
  lastMonthList: [],
  olderList: [],
  datePickerList: [],
  dateRange: null,
  isDpLoading: false,
  dpLoadMore: false,
  dpPageIndex: 1,
  newTodayConversations: [],
  conversationIds: new Set([""]),
};

enum SideNavDispatchTypes {
  SET_TODAY_LIST = "SET_TODAY_LIST",
  SET_PAST_SEVEN_DAYS_LIST = "SET_PAST_SEVEN_DAYS_LIST",
  SET_THIS_MONTH_LIST = "SET_THIS_MONTH_LIST",
  SET_LAST_MONTH_LIST = "SET_LAST_MONTH_LIST",
  SET_OLDER_LIST = "SET_OLDER_LIST",
  SET_DATE_PICKER_LIST = "SET_DATE_PICKER_LIST",
  SET_DATE_RANGE = "SET_DATE_RANGE",
  SET_DP_LOADING = "SET_DP_LOADING",
  SET_DP_LOAD_MORE = "SET_DP_LOAD_MORE",
  SET_DP_PAGE_INDEX = "SET_DP_PAGE_INDEX",
  SET_NEW_TODAY_CONVERSATIONS = "SET_NEW_TODAY_CONVERSATIONS",
  SET_CONVERSATION_IDS = "SET_CONVERSATION_IDS",
}

function sideNavReducer(state: SideNavState, action: { type: SideNavDispatchTypes; payload?: any }): SideNavState {
  switch (action.type) {
    case SideNavDispatchTypes.SET_TODAY_LIST:
      return { ...state, todayList: action.payload };
    case SideNavDispatchTypes.SET_PAST_SEVEN_DAYS_LIST:
      return { ...state, pastSevenDaysList: action.payload };
    case SideNavDispatchTypes.SET_THIS_MONTH_LIST:
      return { ...state, thisMonthList: action.payload };
    case SideNavDispatchTypes.SET_LAST_MONTH_LIST:
      return { ...state, lastMonthList: action.payload };
    case SideNavDispatchTypes.SET_OLDER_LIST:
      return { ...state, olderList: action.payload };
    case SideNavDispatchTypes.SET_DATE_PICKER_LIST:
      return { ...state, datePickerList: action.payload };
    case SideNavDispatchTypes.SET_DATE_RANGE:
      return { ...state, dateRange: action.payload };
    case SideNavDispatchTypes.SET_DP_LOADING:
      return { ...state, isDpLoading: action.payload };
    case SideNavDispatchTypes.SET_DP_LOAD_MORE:
      return { ...state, dpLoadMore: action.payload };
    case SideNavDispatchTypes.SET_DP_PAGE_INDEX:
      return { ...state, dpPageIndex: action.payload };
    case SideNavDispatchTypes.SET_NEW_TODAY_CONVERSATIONS:
      return { ...state, newTodayConversations: action.payload };
    case SideNavDispatchTypes.SET_CONVERSATION_IDS:
      return { ...state, conversationIds: action.payload };
    default:
      return state;
  }
}

export default function SideNav(sideNavProps: SideNavProps) {
  const { state, dispatch, autoSpeak, skills, userAlias, smartRoutingDetectedSkillLabel, conversationId } =
    sideNavProps;
  console.log("User Alias: {}", userAlias);
  console.log(`https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${userAlias}`);

  const navigate = useNavigate();
  const [sideNavState, sideNavDispatch] = useReducer(sideNavReducer, initialState);

  const {
    todayList,
    pastSevenDaysList,
    thisMonthList,
    lastMonthList,
    olderList,
    datePickerList,
    dateRange,
    isDpLoading,
    dpLoadMore,
    dpPageIndex,
    conversationIds,
  } = sideNavState;

  const currentMonthNumber = new Date().getMonth();
  const lastMonthNumber = currentMonthNumber === 0 ? 11 : currentMonthNumber - 1;

  /**
   * When a conversation is selected, this function populates the messages, sets the conversationId,
   * sets the message count, clears the debug messages, and navigates to the conversation's skill.
   */
  async function openConversation(conversation: Conversation) {
    dispatch({ type: "SET_MESSAGE_HISTORY", messageHistory: [] });
    dispatch({
      type: "SET_CHATBOT_PERSONA",
      chatbotPersona: {
        label: skillsMapping[conversation.detectedSkillId].skillName,
        value: conversation.detectedSkillId,
      },
    });
    dispatch({ type: "SET_SMART_ROUTING_SKILL_LABEL", smartRoutingDetectedSkillLabel: "" });
    dispatch({ type: "SET_SMART_ROUTING_SKILL_VALUE", smartRoutingDetectedSkillValue: "" });
    createConversationLists();
    const skillLabel = skills.find((skill: any) => skill.value === conversation.detectedSkillId);
    let messageObjects: any = [];
    let isNextPageAvailable = true;
    let pageIndex = 1;
    while (isNextPageAvailable) {
      let messageResponse = await getMessagesByConversationId(conversation.conversationId, pageIndex.toString(), "10");
      isNextPageAvailable = messageResponse.isNextPageAvailable;
      pageIndex = pageIndex + 1;
      messageObjects = messageObjects.concat(messageResponse.messages);
    }
    messageObjects.sort((a: any, b: any) => a.tsReceived.localeCompare(b.tsReceived));
    let _messageHistory = [];
    for (const messageObject of messageObjects) {
      // Push user message
      _messageHistory.push({
        autoSpeak: autoSpeak,
        timestamp: messageObject.tsReceived,
        messageId: messageObject.messageId,
        conversationId: messageObject.conversationId,
        message: messageObject.task,
        skill: skillLabel,
        staticReferenceSources: [],
        referencedSources: [],
        citations: [],
        owner: {
          isSelf: true,
          isAI: false,
          avatarUrl: `https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${userAlias}`,
          name: userAlias + "@",
          alias: userAlias,
        },
      });
      // Push AI response
      let showReportFeedbacks = false;
      let rating = "";
      let feedbacks = "";
      let thumbsUp = false;
      let thumbsDown = false;
      if (messageObject.rating === "WEB:GOOD") {
        thumbsUp = true;
      } else if (messageObject.rating != undefined) {
        rating = messageObject.rating;
        feedbacks = messageObject.feedback;
        thumbsDown = true;
      }
      let answerMessage = ERROR_ANSWER;
      let answerReferencedSources = [];
      if (messageObject.answerContent) {
        const answerObject = JSON.parse(messageObject.answerContent);
        answerMessage = answerObject.qAnswer ?? answerObject.finalAnswer ?? answerObject.answer;
        answerReferencedSources = answerObject.qReferencedSources ?? answerObject.referencedSources;
      }
      _messageHistory.push({
        autoSpeak: autoSpeak,
        isLoading: state.isLoading,
        messageCount: state.messageCount,
        timestamp: messageObject.tsReceivedIH ?? messageObject.tsReceived,
        messageId: messageObject.messageId,
        conversationId: messageObject.conversationId,
        message: answerMessage,
        skill: skillLabel,
        staticReferenceSources:
          conversation.detectedSkillId === "sna-web-mec"
            ? ["https://w.amazon.com/bin/view/FOX/Core/Products/MEC/UserGuide/Home"]
            : [],
        referencedSources: answerReferencedSources,
        citations: [],
        owner: {
          isSelf: false,
          isAI: true,
          avatarUrl: `https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid=${userAlias}`,
          name: userAlias + "@",
          alias: userAlias,
        },
        showReportFeedbacks: showReportFeedbacks,
        rating: rating,
        feedbacks: feedbacks,
        thumbsUp: thumbsUp,
        thumbsDown: thumbsDown,
      });
    }
    dispatch({ type: "RESET_DEBUG_MESSAGES" });
    dispatch({ type: "RESET_MESSAGE_COUNT" });
    dispatch({ type: "SET_MESSAGE_HISTORY", messageHistory: _messageHistory });
    dispatch({ type: "SET_CONVERSATION_ID", conversationId: conversation.conversationId });
    dispatch({ type: "SET_MESSAGE_COUNT", messageCount: _messageHistory.length / 2 });
    dispatch({ type: "SET_IS_SKILL_LOCKED", isSkillLocked: true });
    navigate(`/skills/${conversation.detectedSkillId}?autoSpeak=${autoSpeak}`);
  }

  const createConversationLists = async () => {
    if (!sideNavState.conversationIds.has(conversationId)) {
      const conversationHistoryResponse = await getConversationHistory("", "", "1", "1");
      const conversation = conversationHistoryResponse.conversations[0];
      sideNavDispatch({
        type: SideNavDispatchTypes.SET_CONVERSATION_IDS,
        payload: sideNavState.conversationIds.add(conversation.conversationId),
      });
      const newConversationList = [conversation, ...sideNavState.newTodayConversations];
      sideNavDispatch({ type: SideNavDispatchTypes.SET_NEW_TODAY_CONVERSATIONS, payload: newConversationList });
      sideNavDispatch({
        type: SideNavDispatchTypes.SET_TODAY_LIST,
        payload: newConversationList.map((todayConversation) => (
          <div style={{ paddingTop: 5, paddingBottom: 5 }}>
            <Button variant="inline-link" onClick={() => openConversation(todayConversation)}>
              {todayConversation.conversationTopic}
            </Button>
          </div>
        )),
      });
    } else if (conversationIds.size === 1) {
      const conversationHistoryResponse = await getConversationHistory("", "", "1", "10");
      const conversationHistory = conversationHistoryResponse.conversations;

      const today = [];
      const pastSevenDays = [];
      const thisMonth = [];
      const lastMonth = [];
      const olderConversations = [];
      const now = new Date();

      for (const conversation of conversationHistory) {
        sideNavDispatch({
          type: SideNavDispatchTypes.SET_CONVERSATION_IDS,
          payload: sideNavState.conversationIds.add(conversation.conversationId),
        });
        const lastMessage = new Date(conversation.updated);

        if (lastMessage.toString().substr(0, 10) === now.toString().substr(0, 10)) {
          today.push(conversation);
        } else if (lastMessage.getTime() > now.getTime() - 7 * 24 * 60 * 60 * 1000) {
          pastSevenDays.push(conversation);
        } else if (now.getDate() > 7 && lastMessage.getMonth() === currentMonthNumber) {
          thisMonth.push(conversation);
        } else if (lastMessage.getMonth() === lastMonthNumber) {
          lastMonth.push(conversation);
        } else {
          olderConversations.push(conversation);
        }
      }
      sideNavDispatch({ type: SideNavDispatchTypes.SET_NEW_TODAY_CONVERSATIONS, payload: today });
      sideNavDispatch({
        type: SideNavDispatchTypes.SET_TODAY_LIST,
        payload: today.map((todayConversation) => (
          <div style={{ paddingTop: 5, paddingBottom: 5 }}>
            <Button variant="inline-link" onClick={() => openConversation(todayConversation)}>
              {todayConversation.conversationTopic}
            </Button>
          </div>
        )),
      });
      sideNavDispatch({
        type: SideNavDispatchTypes.SET_PAST_SEVEN_DAYS_LIST,
        payload: pastSevenDays.map((pastSevenDaysConversation) => (
          <div style={{ paddingTop: 5, paddingBottom: 5 }}>
            <Button variant="inline-link" onClick={() => openConversation(pastSevenDaysConversation)}>
              {pastSevenDaysConversation.conversationTopic}
            </Button>
          </div>
        )),
      });
      sideNavDispatch({
        type: SideNavDispatchTypes.SET_THIS_MONTH_LIST,
        payload: thisMonth.map((thisMonthConversation) => (
          <div style={{ paddingTop: 5, paddingBottom: 5 }}>
            <Button variant="inline-link" onClick={() => openConversation(thisMonthConversation)}>
              {thisMonthConversation.conversationTopic}
            </Button>
          </div>
        )),
      });
      sideNavDispatch({
        type: SideNavDispatchTypes.SET_LAST_MONTH_LIST,
        payload: lastMonth.map((lastMonthConversation) => (
          <div style={{ paddingTop: 5, paddingBottom: 5 }}>
            <Button variant="inline-link" onClick={() => openConversation(lastMonthConversation)}>
              {lastMonthConversation.conversationTopic}
            </Button>
          </div>
        )),
      });
      sideNavDispatch({
        type: SideNavDispatchTypes.SET_OLDER_LIST,
        payload: olderConversations.map((olderConversation) => (
          <div style={{ paddingTop: 5, paddingBottom: 5 }}>
            <Button variant="inline-link" onClick={() => openConversation(olderConversation)}>
              {olderConversation.conversationTopic}
            </Button>
          </div>
        )),
      });
    }
  };

  useEffect(() => {
    createConversationLists();
  }, [conversationId]);

  const createDpConversationLists = async () => {
    if (dateRange) {
      sideNavDispatch({ type: SideNavDispatchTypes.SET_DP_PAGE_INDEX, payload: 1 });
      sideNavDispatch({ type: SideNavDispatchTypes.SET_DP_LOADING, payload: true });
      const conversationHistoryResponse = await getConversationHistory(
        dateRange.startDate,
        dateRange.endDate,
        "1",
        "10"
      );
      const conversationHistory = conversationHistoryResponse.conversations;
      sideNavDispatch({
        type: SideNavDispatchTypes.SET_DP_LOAD_MORE,
        payload: conversationHistoryResponse.isNextPageAvailable,
      });
      if (conversationHistoryResponse.isNextPageAvailable) {
        sideNavDispatch({ type: SideNavDispatchTypes.SET_DP_PAGE_INDEX, payload: dpPageIndex + 1 });
      }

      sideNavDispatch({
        type: SideNavDispatchTypes.SET_DATE_PICKER_LIST,
        payload: conversationHistory.map((conversation: Conversation) => (
          <div style={{ paddingTop: 5, paddingBottom: 5 }}>
            <Button variant="inline-link" onClick={() => openConversation(conversation)}>
              {conversation.conversationTopic}
            </Button>
          </div>
        )),
      });
      sideNavDispatch({ type: SideNavDispatchTypes.SET_DP_LOADING, payload: false });
    }
  };

  useEffect(() => {
    createDpConversationLists();
  }, [dateRange]);

  async function loadMore() {
    if (dateRange) {
      sideNavDispatch({ type: SideNavDispatchTypes.SET_DP_LOADING, payload: true });
      const conversationHistoryResponse = await getConversationHistory(
        dateRange.startDate,
        dateRange.endDate,
        dpPageIndex.toString(),
        "10"
      );
      const conversationHistory = conversationHistoryResponse.conversations;
      sideNavDispatch({
        type: SideNavDispatchTypes.SET_DP_LOAD_MORE,
        payload: conversationHistoryResponse.isNextPageAvailable,
      });
      if (conversationHistoryResponse.isNextPageAvailable) {
        sideNavDispatch({ type: SideNavDispatchTypes.SET_DP_PAGE_INDEX, payload: dpPageIndex + 1 });
      }

      sideNavDispatch({
        type: SideNavDispatchTypes.SET_DATE_PICKER_LIST,
        payload: datePickerList.concat(
          conversationHistory.map((conversation: Conversation) => (
            <div style={{ paddingTop: 5, paddingBottom: 5 }}>
              <Button variant="inline-link" onClick={() => openConversation(conversation)}>
                {conversation.conversationTopic}
              </Button>
            </div>
          ))
        ),
      });
      sideNavDispatch({ type: SideNavDispatchTypes.SET_DP_LOADING, payload: false });
    }
  }

  return (
    <HelpPanel header="⠀">
      <Header
        variant="h2"
        actions={
          <Button
            data-testid="new-conversation-button"
            ariaLabel="New conversation"
            variant="icon"
            iconName="add-plus"
            onClick={() => {
              location.reload();
            }}
          />
        }
      >
        Conversations
      </Header>
      {todayList.length > 0 ? (
        <div style={{ paddingTop: 10 }}>
          <Header variant="h2">Today</Header>
        </div>
      ) : (
        <></>
      )}
      {todayList}
      {pastSevenDaysList.length > 0 ? (
        <div style={{ paddingTop: 10 }}>
          <Header variant="h2">Past 7 Days</Header>
        </div>
      ) : (
        <></>
      )}
      {pastSevenDaysList}
      {thisMonthList.length > 0 ? (
        <div style={{ paddingTop: 10 }}>
          <Header variant="h2">{new Date().toLocaleString("default", { month: "long" })}</Header>
        </div>
      ) : (
        <></>
      )}
      {thisMonthList}
      {lastMonthList.length > 0 ? (
        <div style={{ paddingTop: 10 }}>
          <Header variant="h2">{new Date(new Date().setDate(0)).toLocaleString("default", { month: "long" })}</Header>
        </div>
      ) : (
        <></>
      )}
      {lastMonthList}
      {olderList.length > 0 ? (
        <div style={{ paddingTop: 10 }}>
          <Header variant="h2">Older Conversations</Header>
        </div>
      ) : (
        <></>
      )}
      {olderList}
      <div style={{ overflow: "hidden", margin: "auto", borderRight: "0.5mm solid", marginTop: 30 }}>
        <DateRangePicker
          onChange={({ detail }) =>
            sideNavDispatch({
              type: SideNavDispatchTypes.SET_DATE_RANGE,
              payload: detail.value as DateRangePickerProps.AbsoluteValue,
            })
          }
          value={dateRange}
          relativeOptions={[]}
          isValidRange={(range) => {
            if (range?.type === "absolute") {
              const [startDateWithoutTime] = range.startDate.split("T");
              const [endDateWithoutTime] = range.endDate.split("T");
              if (!startDateWithoutTime || !endDateWithoutTime) {
                return {
                  valid: false,
                  errorMessage:
                    "The selected date range is incomplete. Select a start and end date for the date range.",
                };
              }
              if (new Date(range.startDate).getTime() - new Date(range.endDate).getTime() > 0) {
                return {
                  valid: false,
                  errorMessage: "The selected date range is invalid. The start date must be before the end date.",
                };
              }
            }
            return { valid: true };
          }}
          i18nStrings={{
            applyButtonLabel: "Apply",
            cancelButtonLabel: "Cancel",
          }}
          dateOnly
          expandToViewport
          placeholder="Filter by date"
          rangeSelectorMode="absolute-only"
        />
      </div>
      {isDpLoading ? (
        <div style={{ paddingTop: 10 }}>
          <Spinner size="large" />
        </div>
      ) : (
        <div>
          {datePickerList.length > 0 ? (
            <div style={{ paddingTop: 10 }}>
              <Header variant="h2">
                <p>
                  Conversations from <i>{dateRange!.startDate}</i> to <i>{dateRange!.endDate}</i>
                </p>
              </Header>
            </div>
          ) : (
            <></>
          )}
          {datePickerList}
        </div>
      )}
      {dpLoadMore ? (
        <div style={{ paddingTop: 10 }}>
          <Button variant="primary" onClick={() => loadMore()}>
            Load More
          </Button>
        </div>
      ) : (
        <></>
      )}
    </HelpPanel>
  );
}
